<template>
  <div v-if="sizeType == 'full_screen'" class="new-update-member-container">
    <div class="header">
      <p>עדכון פרטי חבר מועדון</p>
      <p>כאן תוכלו לעדכן את פרטי המועדון של הלקוח</p>
      <div class="client-number">
        <small>לקוח: {{ values.clientNumber }}</small>
      </div>
    </div>
    <div class="section-header">
      <h3 style="text-align:center;">פרטים אישיים</h3>
    </div>
    <div class="section section-a">
      <div class="box">
        <p>שם מלא</p>
        <InputText
          type="text"
          placeholder="שם מלא"
          style="width:100%"
          v-model="values.fullName"
        />
        <p class="mt">מין</p>
        <Dropdown
          :options="gender_options"
          placeholder="אנא בחר/י"
          style="width:100%;"
          v-model="values.gender"
        />
      </div>
      <div class="box">
        <p>ת.ז/דרכון</p>
        <InputText
          type="text"
          placeholder="תז/דרכון"
          style="width:100%"
          v-model="values.idNumber"
        />
        <p class="mt">יום הולדת</p>
        <input type="date" style="width:100%" v-model="values.birthDay" />
      </div>
    </div>
    <div class="section-header">
      <h3 style="text-align:center;">פרטי התקשרות</h3>
    </div>
    <div class="section section-b">
      <div class="box">
        <p>נייד</p>
        <InputText
          type="tel"
          name = "phone"
          placeholder="טלפון נייד"
          style="width:100%"
          v-model="values.phoneNumber"
        />
        <p class="mt">דוא"ל</p>
        <InputText
          type="email"
          name="email"
          placeholder="דואל"
          style="width:100%"
          v-model="values.email"
        />
      </div>
    </div>
    <div class="section-header">
      <h3 style="text-align:center;">כתובת מגורים</h3>
    </div>
    <div class="section section-a">
      <div class="box">
        <p>יישוב</p>
        <InputText
          type="text"
          placeholder="יישוב"
          style="width:100%"
          v-model="values.city"
        />
        <p class="mt">מס בית</p>
        <InputNumber
          v-model="values.houseNumber"
          mode="decimal"
          :useGrouping="false"
          style="width:100%"
          placeholder="מס' בית"
        />
      </div>
      <div class="box">
        <p>רחוב</p>
        <InputText
          type="text"
          placeholder="רחוב"
          style="width:100%"
          v-model="values.street"
        />
        <p class="mt">דירה</p>
        <InputNumber
          v-model="values.apartmentNumber"
          mode="decimal"
          :useGrouping="false"
          style="width:100%"
          placeholder="דירה"
        />
      </div>
    </div>
    <div class="section-header">
      <h3 style="text-align:center;">בחר מתוך הרשימה את העדכונים שביצעת</h3>
    </div>
    <div class="section section-b">
      <div class="box">
        <p>בחירה עדכונים לבחירה</p>
        <MultiSelect
          v-model="values.multi_select_values"
          :options="multi_select_options"
          placeholder="נא לבחור אחד או יותר"
          style="width:100%"
        />
        <p class="mt">אם ברצונך לעדכן פרטים אחרים אנא ציין כאן מהם</p>
        <InputText
          type="text"
          placeholder="פרטים נוספים"
          style="width:100%"
          v-model="values.additionDetails"
        />
      </div>
    </div>
    <div class="section section-b">
      <div class="box">
        <p>שם העובד המבקש</p>
        <InputText
          type="text"
          placeholder="נא לרשום שם מלא"
          style="width:100%"
          v-model="values.ownerName"
        />
        <p class="mt">דוא"ל של הסניף המבקש להמשך עדכונים</p>
        <InputText
          type="text"
          style="width:100%"
          v-model="values.brancheEmail"
        />
        <p class="mt">שם סניף המבקש</p>
        <InputText
          type="text"
          style="width:100%"
          v-model="values.branche"
          disabled
        />
      </div>
    </div>
    <div class="btn">
      <Button
        label="עדכן"
        class="p-button-secondary"
        style="width:80%;"
        @click="handleSubmit"
      />
    </div>
  </div>

  <!-- for mobile -->
  <div v-if="sizeType == 'mobile'" class="new-update-container-mobile">
    <div class="header-mobile">
      <p>עדכון פרטי חבר מועדון</p>
      <p>כאן תוכלו לעדכן את פרטי המועדון של הלקוח</p>
      <div class="client-number">
        <small>לקוח:{{ values.clientNumber }}</small>
      </div>
    </div>
    <div class="section-header">
      <h3 style="text-align:center;">פרטים אישיים</h3>
    </div>
    <div class="phone-field">
      <p>ת.ז/דרכון</p>
      <InputText
        type="text"
        placeholder="תז/דרכון"
        style="width:100%"
        v-model="values.idNumber"
      />
    </div>
    <div class="phone-field">
      <p>שם מלא</p>
      <InputText
        type="text"
        placeholder="שם מלא"
        style="width:100%"
        v-model="values.fullName"
      />
    </div>
    <div class="phone-field">
      <p class="mt">יום הולדת</p>
      <input type="date" style="width:100%" v-model="values.birthDay" />
    </div>
    <div class="phone-field">
      <p>מין</p>
      <Dropdown
        v-model="values.gender"
        :options="gender_options"
        placeholder="אנא בחר/י"
        style="width:100%;"
      />
    </div>
    <div class="section-header">
      <h3 style="text-align:center;">פרטי התקשרות</h3>
    </div>
    <div class="phone-field">
      <p>נייד</p>
      <InputText
        type="text"
        placeholder="טלפון נייד"
        style="width:100%"
        v-model="values.phoneNumber"
      />
    </div>
    <div class="phone-field">
      <p class="mt">דוא"ל</p>
      <InputText
        type="text"
        placeholder="דואל"
        style="width:100%"
        v-model="values.email"
      />
    </div>
    <div class="section-header">
      <h3 style="text-align:center;">כתובת מגורים</h3>
    </div>
    <div class="phone-field">
      <p>יישוב</p>
      <InputText
        type="text"
        placeholder="יישוב"
        style="width:100%"
        v-model="values.city"
      />
    </div>
    <div class="phone-field">
      <p>רחוב</p>
      <InputText
        type="text"
        placeholder="רחוב"
        style="width:100%"
        v-model="values.street"
      />
    </div>
    <div class="phone-field">
      <p>מס בית</p>
      <InputNumber
        v-model="values.houseNumber"
        mode="decimal"
        :useGrouping="false"
        style="width:100%"
        placeholder="מס' בית"
      />
    </div>
    <div class="phone-field">
      <p>דירה</p>
      <InputNumber
        v-model="values.apartmentNumber"
        mode="decimal"
        :useGrouping="false"
        style="width:100%"
        placeholder="דירה"
      />
    </div>
    <div class="section-header">
      <h3 style="text-align:center;">בחר מתוך הרשימה את העדכונים שביצעת</h3>
    </div>
    <div class="phone-field">
      <p>בחירה עדכונים לבחירה</p>
      <MultiSelect
        v-model="values.multi_select_values"
        :options="multi_select_options"
        placeholder="נא לבחור אחד או יותר"
        style="width:100%"
      />
    </div>
    <div class="phone-field">
      <p>אם ברצונך לעדכן פרטים אחרים אנא ציין כאן מהם</p>
      <InputText
        type="text"
        placeholder="פרטים נוספים"
        style="width:100%"
        v-model="values.additionDetails"
      />
    </div>
    <div class="phone-field">
      <p>שם העובד המבקש</p>
      <InputText
        type="text"
        placeholder="נא לרשום שם מלא"
        style="width:100%"
        v-model="values.ownerName"
      />
    </div>
    <div class="phone-field">
      <p>דוא"ל של הסניף המבקש להמשך עדכונים</p>
      <InputText type="text" style="width:100%" v-model="values.brancheEmail" />
    </div>
    <div class="phone-field">
      <p>שם סניף המבקש</p>
      <InputText
        type="text"
        style="width:100%"
        v-model="values.branche"
        disabled
      />
    </div>
    <div class="btn">
      <Button
        label="עדכן"
        class="p-button-secondary"
        style="width:80%;"
        @click="handleSubmit"
      />
    </div>
  </div>

  <!-- JUST FOR MAIL -->
  <div class="form-email" id="form-email" v-show="false">
    <table
      style="width:50%; border-collapse: collapse; font-size: 1.1rem; margin: 1rem 0;"
    >
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          סניף/שם
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.branche }}
        </td>
      </tr>
      <tr>
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          מספר לקוח
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.clientNumber }}
        </td>
      </tr>
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          שם הלקוח
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.fullName }}
        </td>
      </tr>
      <tr>
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          דוא"ל של הלקוח
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.email ? values.email : "לא צויין" }}
        </td>
      </tr>
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding:8px;">
          יום הולדת
        </th>
        <td
          v-if="values.birthDay instanceof Date"
          style="border:1px solid #dddddd; text-align:right; padding:8px;"
        >
          {{ values.birthDay.toLocaleDateString("he") }}
        </td>
      </tr>
      <tr>
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          ת"ז
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.idNumber }}
        </td>
      </tr>
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding:8px;">
          מין
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding:8px;">
          {{ values.gender }}
        </td>
      </tr>
      <tr>
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          טלפון
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.phoneNumber }}
        </td>
      </tr>
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          פרטים נוספים
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.additionDetails ? values.additionDetails : "לא צויין" }}
        </td>
      </tr>
      <tr>
        <th style="border:1px solid #dddddd; text-align:right; padding:8px;">
          שם העובד המבקש
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding:8px;">
          {{ values.ownerName }}
        </td>
      </tr>
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          סומן בבחירה מרובה
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.multi_select_values.join(", ") }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
/* eslint-disable */
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import InputNumber from 'primevue/inputnumber';
import MultiSelect from 'primevue/multiselect';
import {projectFirestore,projectFunctions} from '../../../../firebase/config'
import {alert} from '../../../../Methods/Msgs'
import { ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
export default {
  props:['clientNumber','user'],
  components: { Dropdown, InputText,InputNumber,MultiSelect },
  emits:['close'],
  setup(props,{emit}){
      const error = ref('')
      const sizeType = ref('full_screen')
      const gender_options = ref([
          'זכר',
          'נקבה'
      ])
      const multi_select_options = ref([
          'שם מלא',
          'תעודת זהות',
          'נייד',
          'כתובת',
          'דואר אלקטרוני',
          'קבלת SMS בעברית',
          'קבלת SMS ברוסית',
          'ביטול SMS',
          'תאריך לידה'
      ])

      const values = ref({
          idNumber:'',
          clientNumber:props.clientNumber,
          fullName:'',
          birthDay:'',
          gender:'',
          phoneNumber:'',
          email:'',
          city:'',
          street:'',
          houseNumber:0,
          apartmentNumber:0,
          multi_select_values:[],
          additionDetails:'',
          ownerName:'',
          branche:props.user.name,
          brancheEmail:props.user.email,
          status:'חדש',
          createdAt:new Date()
      })

      const validation = ()=>{
          for (var key in values.value) {
                if (key == "idNumber") {
                    if (values.value[key].length == 0) {
                        error.value = " נא למלא מס זהות/דרכון בפרטים אישיים";
                        return false;
                    }
                }
                if (key == "fullName") {
                    if (values.value[key].length == 0) {
                        error.value = "נא למלא שם מלא בפרטים אישיים";
                        return false;
                    }
                }
                if (key == "gender") {
                    if (values.value[key].length == 0) {
                        error.value = "נא למלא מין בפרטים אישיים";
                        return false;
                    }
                }
                if (key == "birthDay") {
                    if (values.value[key].length == 0) {
                        error.value = "נא למלא תאריך יום הולדת בפרטים אישיים";
                        return false;
                    }
                }
                if (key == "phoneNumber") {
                    if (values.value[key].length != 10) {
                        error.value = "נא למלא מספר טלפון נייד בין 10 ספרות";
                        return false;
                    }
                }
                if (key == "multi_select_values") {
                    if (values.value[key].length == 0) {
                        error.value = "נא לסמן אחד או יותר מתוך הרשימה של העדכונים ביצעת";
                        return false;
                    }
                }
                if (key == "ownerName") {
                    if (values.value[key].length == 0) {
                        error.value = "נא למלא את שם העובד/ת המבקש/ת";
                        return false;
                    }
                }
          }
          return true
      }
      const handleSubmit = async()=>{
          if(!validation()){
              alert('error','אופס...',error.value)
          }else{
              values.value.birthDay = new Date(values.value.birthDay)
              await storeInDb()
              alert('success','הצלחה','הטופס עודכן בהצלחה ונשלח לממונה')
              .then(()=>{
                   sendEmailToUser(values.value)
                   sendEmailToCharge()
                   emit('close')
               })
          }
      }
      const storeInDb = async()=>{
        const doc = projectFirestore.collection("Applications")
        .doc("7Q1KrNG9Mlm9udT8KBib").collection("Update_club_member").doc();
        await doc.set({ ...values.value, id: doc.id });
      }
    
      function sendEmailToCharge() {
          projectFunctions.httpsCallable('send_email')({
              html: document.getElementById("form-email").innerHTML,
              destinations:["rosman.mate@gmail.com"],
              from:"עדכון לקוח מועדון",
              title:`מס לקוח ${values.value.clientNumber}, סניף:${values.value.branche}`
          })
      }

      function sendEmailToUser(currentDetails) {
        projectFunctions.httpsCallable('send_email')({
            html: `<div>
                <h3>
                    שלום ${currentDetails.ownerName},
                </h3>
                <h3>
                    בקשתך לעדכון ${currentDetails.multi_select_values.join(', ')}
                </h3>
                <h3>
                   !נשלחו בהצלחה לטיפול
                </h3>
                <h3 style="color:red;">
                  לידיעתך, עדכון הפרטים במערכת יבוצע תוך מספר ימים.
                </h3>
            </div>`,
            destinations:[`${currentDetails.brancheEmail}`],
            from:"עדכון לקוח מועדון",
            title:"בקשה לעדכון פרטי מועדון"
        }) 
      }

      window.addEventListener('resize', () => {
            if(window.innerWidth<=600){
                sizeType.value = 'mobile'
            }
            if(window.innerWidth>600){
                sizeType.value = 'full_screen'
            }
      });
      onMounted(()=>{
          if(window.innerWidth<=600){
                sizeType.value = 'mobile'
            }
            if(window.innerWidth>600){
                sizeType.value = 'full_screen'
            }
      })
      return{gender_options,multi_select_options,sizeType,values,handleSubmit,error}
  }
};
</script>

<style scoped>
.new-update-member-container {
  width: 40vw;
  height: auto;
  background-color: #e2dddd;
  padding: 0 10px;
}
.header {
  position: relative;
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.client-number{
    position: absolute;
    top: 0;
    left: 0;
    
}
.header p:nth-child(1) {
  font-size: 2.5rem;
  font-weight: bold;
}
.header p:nth-child(2) {
  font-size: 1.1rem;
}

input[type="date"] {
  height: 35px;
  border: 0.5px solid lightgrey;
  border-radius: 3px;
}
input[type="date"]:hover {
  border: 1.1px solid lightblue;
}
input[type="date"]:focus {
  outline: none;
  border: 1.1px solid lightblue;
}
.section-header{
    width: 100%;
    margin-top: 15px;
    border-bottom: 2px solid #bfbfbf;
}
.section {
  width: 100%;
  height: 25%;
  min-height: 25%;
}
.section-a{
    display: flex;
    flex-direction: row;
}
.box{
    width: 100%;
    height: 100%;
    padding: 2.4rem;
}
.mt{
    margin-top: 20px;
}
p{
    font-weight: bold;
}
.btn{
    width: 100%;
    height: 100px;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* for mobile */
.new-update-container-mobile{
    width: 80vw;
    height: auto;
    background-color: #e2dddd;
    padding: 0 10px;
}
.phone-field{
    margin-top: 15px;
    width: 100%;
}
.header-mobile{
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.header-mobile p:nth-child(1) {
  font-size: 1.7rem;
  font-weight: bold;
}
.header-mobile p:nth-child(2) {
  font-size: .9rem;
}
small{
    font-size: .7rem;
    font-weight: bold;
}
</style>
