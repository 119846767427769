<template>
  <div class="update-club-member-main">
      <Button @click="handleShowClientNumberPage" style="width:260px; height:100px; margin:5px; font-size: 2rem;" label="טופס עדכון פרטים" class="p-button-success" />
      <Button v-if="user_role>=99" @click="showManageUpdateMemberDialog = !showManageUpdateMemberDialog" style="width:260px; height:100px; margin:5px; font-size: 2rem;" label="ניהול טפסים" class="p-button-warning" />
      <Dialog header="טופס עדכון פרטי חבר מועדון"  v-model:visible="showNewUpdateMemberDialog">
          <NewUpdateMember
                @close="showNewUpdateMemberDialog = !showNewUpdateMemberDialog"
                :clientNumber="clientNumber"
                :user="userProfile"
          />
      </Dialog>
      <Dialog header="ניהול בקשות לעדכון פרטי מועדון"  v-model:visible="showManageUpdateMemberDialog">
        <ManageUpdateMember />
      </Dialog>
      <Dialog
      header="מספר לקוח"
      :modal="true"
      v-model:visible="showClientNumberPage"
    >
      <div class="client-number-page">
        <h3 style="text-align:center;">הזן מספר לקוח</h3>
        <InputText
          type="text"
          placeholder="מספר לקוח"
          style="width:100%;"
          v-model="clientNumber"
        />
        <Button
          label="המשך"
          class="p-button-success"
          style="width:100%; margin-top:2px;"
          @click="handleContinue"
        />
      </div>
    </Dialog>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import {alert} from '../../../Methods/Msgs';
import store from '../../../store';
import NewUpdateMember from '../update_club_member/components/NewUpdateMember.vue'
import ManageUpdateMember from '../update_club_member/components/ManageUpdateMember.vue'
import { computed } from '@vue/runtime-core';

export default {
    components:{Dialog,InputText,NewUpdateMember,ManageUpdateMember},
    setup(){
        const showNewUpdateMemberDialog = ref(false)
        const showManageUpdateMemberDialog = ref(false)
        const showClientNumberPage = ref(false)
        const clientNumber = ref('')
        const handleShowClientNumberPage = ()=>{
            clientNumber.value = ''
            showClientNumberPage.value = !showClientNumberPage.value
        }
        const handleContinue = ()=>{
            if(!clientNumber.value){
                alert('error','אופס...','עליך להזין מספר לקוח על מנת להמשיך')
            }else{
                showClientNumberPage.value = !showClientNumberPage.value
                showNewUpdateMemberDialog.value = !showNewUpdateMemberDialog.value
            }
        }
        const user_role = ref(computed(()=>{
            return store.getters.role
        }))
        const userProfile = ref(computed(() => {
            return store.getters.user;
        }));

        return{user_role,userProfile,showNewUpdateMemberDialog,showClientNumberPage,showManageUpdateMemberDialog,clientNumber,handleShowClientNumberPage,handleContinue}
    }
}
</script>

<style scoped>
    .update-club-member-main{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .client-number-page{
        width: 20vw;
        height: 10vh;
    }
    @media only screen and (max-width: 600px) {
        .client-number-page{
            width: 70vw;
            height: 15vh;
        }
    }
</style>